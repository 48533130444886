import { Component, Input } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
  selector: 'listo-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent {
  @Input() name = '';
  @Input() label: string;
  @Input() autofocus = false;
  @Input() required = false;
  @Input() loading = false;
  @Input() type?: string;
  @Input() mask?: any = false;
  @Input() form = new FormControl();

  focus = false;

  get pending() {
    const control = this.form.get(this.name);
    return (control && control.pending) || this.loading;
  }

  isRequired() {
    if (this.required) {
      return true;
    }
    if (!this.form) {
      return false;
    }
    const control = this.form.get(this.name);
    if (!control || !control.validator) {
      return false;
    }
    const validation = control.validator(<AbstractControl>{});
    return !!(validation && validation.required)
  }

  get error() {
    const control = this.form.get(this.name);
    if (!control || control.untouched || !control.errors) {
      return;
    }
    const type = Object.keys(control.errors)[0];
    return {
      type,
      value: control.errors[type],
    };
  }
}
