import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ListoCommonService {
  private _phone: string;
  private _steps: string[] = [];

  get phone() {
    return this._phone;
  }

  set phone(phone: string) {
    this._phone = phone;
  }

  get steps() {
    return this._steps;
  }

  set steps(steps: string[]) {
    this._steps = steps;
  }
}
