import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InsuranceType } from '../../utils/types';
import { INSURANCE_TYPE_OPTIONS } from '../../utils/constants';

export interface InsuranceTypesProperties {
  Term?: any,
  'Guaranteed Universal'?: any,
  'Whole Life'?: any,
}

@Component({
  selector: 'app-insurance-type-select',
  templateUrl: './insurance-type-select.component.html',
  styleUrls: ['./insurance-type-select.component.scss'],
})
export class InsuranceTypeSelectComponent {
  @Input() amounts: InsuranceTypesProperties = {};
  @Input() value: InsuranceType = 'Term Life';
  @Output() change = new EventEmitter<InsuranceType>();
  insuranceTypes = INSURANCE_TYPE_OPTIONS;
  insuranceTypeImages: InsuranceTypesProperties = {
    Term: "./assets/images/temporal.svg",
    'Guaranteed Universal': "./assets/images/universal.svg",
    'Whole Life': "./assets/images/full_coverage.svg",
  };

  onCoverageClick(coverageType: InsuranceType) {
    if (this.amounts[this.value] !== 0) {
      this.change.emit(coverageType);
    }
  }
}
