import { Component, Input, OnInit } from '@angular/core';
import { PHONE_NUMBER } from '../../../utils/constants';
import { NavigationEnd, Router } from '@angular/router';
import { getSegmentPage } from '../../../utils/operations';

const PHONE_NUMBER_EVENT = 'ClickedPhoneNumber';

@Component({
  selector: 'listo-phone-link',
  templateUrl: './phone-link.component.html',
  styleUrls: ['./phone-link.component.scss'],
})
export class PhoneLinkComponent implements OnInit {
  @Input() class: string;
  PHONE_NUMBER = PHONE_NUMBER;
  eventName = 'ClickedPhoneNumber';
  url: string;

  constructor(private route: Router) {}

  ngOnInit() {
    this.url = this.route.url;
    this.route.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.eventName = `${getSegmentPage()} ${PHONE_NUMBER_EVENT}`;
        this.url = window.location.pathname;
      }
    });
  }
}
