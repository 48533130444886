import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'listo-tooltip-window',
  templateUrl: './tooltip-window.component.html',
  styleUrls: ['./tooltip-window.component.scss'],
})
export class TooltipWindowComponent implements OnInit, OnDestroy {
  @Input() anchorElement: HTMLElement;
  top: number = 0;
  left: number = 0;

  constructor(private ref: ElementRef) { }

  ngOnInit() {
    const element: HTMLElement = this.ref.nativeElement;
    document.body.appendChild(element);
    const bottom = this.anchorElement.offsetTop + this.anchorElement.offsetHeight;
    const left = this.anchorElement.offsetLeft + this.anchorElement.offsetWidth / 2;
    element.style.top = `${bottom}px`;
    element.style.left = `${left}px`;
  }

  ngOnDestroy() {
    this.ref.nativeElement.remove();
  }
}
