import { Component, Input } from '@angular/core';
import { Rate } from '../../utils/types';

@Component({
  selector: 'app-rate-card',
  templateUrl: './rate-card.component.html',
  styleUrls: ['./rate-card.component.scss'],
})
export class RateCardComponent {
  @Input() rate: Rate;
}
