import { Router } from '@angular/router';
import { Component, HostBinding, Input } from '@angular/core';
import { SegmentService } from '../../segment.service';
import { getSegmentPage } from '../../../utils/operations';

@Component({
  selector: 'app-insurance-type',
  templateUrl: './insurance-type.component.html',
  styleUrls: ['./insurance-type.component.scss'],
})
export class InsuranceTypeComponent {
  @Input() image: string;
  @Input() name: string;
  @Input() amount: number;

  @HostBinding('class.selected')
  @Input() selected: boolean;

  constructor(
    private router: Router,
    private segment: SegmentService,
  ) {}

  onContactAgent() {
    this.segment.track(`${getSegmentPage()} AgentSelected`);
    this.router.navigate(['personal'], { queryParamsHandling: "preserve" } );
  }
}
