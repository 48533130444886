export type Gender = 'male' | 'female';

export interface UserData {
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  email?: string;
  gender?: Gender;
  age?: number;
  birthdate?: string;
  zipCode?: number;
  tobaccoUse?: boolean;
  insuranceType?: InsuranceType;
  coverage?: number;
  coverage2?: number;
  monthly_premium?: number;
  provider?: string;
  product?: string;
  customer_id?: string;
  transaction_id?: string;
  contactPreference?: string;
  serviceOptIn?: string;
  marketingOptIn?: string;
  contactTime?: string;
  sid?: string;
  cid?: string;
  referrer?: string;
  url_params?: any;
  lead_came_from?: string;
  city?: string;
  stateCode?: string;
  applicationId?: string;
  tsUrl?: string;

}

export interface Store {
  short_name: string;
  longitude: string;
  latitude: string;
  address: string;
  phone_number: string;
}

export type InsuranceType = 'Term Life' | 'Guaranteed Acceptance Whole Life' | 'Whole Life'

export interface InsuranceTypeOption {
  value: InsuranceType;
  label: string;
}

export interface GetRatesRequest {
  insuranceType: InsuranceType;
  coverage: number;
  length: number;
  gender: Gender;
  age: number;
  tobaccoUse: boolean;
  zipCode: number,
}

export interface Rate {
  companyName: string,
  product: string,
  logo: string
  coverage: number,
  frequency: string,
  premium: number,
  productCode: string,
  applying: boolean

}

export class SegmentAnalytics extends Array {
  SNIPPET_VERSION = '4.1.0';
  methods = [
    'trackSubmit',
    'trackClick',
    'trackLink',
    'trackForm',
    'pageview',
    'identify',
    'reset',
    'group',
    'track',
    'ready',
    'alias',
    'debug',
    'page',
    'once',
    'off',
    'on',
  ];

  constructor() {
    super();
    this.methods.forEach(method => {
      this[method] = this.factory(method);
    });
  }

  factory = (method) => (...args) => {
    this.push([method, ...args]);
    return this;
  }

  user = () => ({
    anonymousId: () => '',
  })

  // @ts-ignore
  trackSubmit(...args: any[]);

  // @ts-ignore
  trackClick(...args: any[]);

  // @ts-ignore
  trackLink(...args: any[]);

  // @ts-ignore
  trackForm(...args: any[]);

  // @ts-ignore
  pageview(...args: any[]);

  // @ts-ignore
  identify(...args: any[]);

  // @ts-ignore
  reset(...args: any[]);

  // @ts-ignore
  group(...args: any[]);

  // @ts-ignore
  track(...args: any[]);

  // @ts-ignore
  ready(...args: any[]);

  // @ts-ignore
  alias(...args: any[]);

  // @ts-ignore
  debug(...args: any[]);

  // @ts-ignore
  page(...args: any[]);

  // @ts-ignore
  once(...args: any[]);

  // @ts-ignore
  off(...args: any[]);

  // @ts-ignore
  on(...args: any[]);
}
