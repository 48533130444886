import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { ListoCommonModule } from './listo-common/listo-common.module';
import { WelcomeComponent } from './welcome/welcome.component';
import { AppRoutingModule } from './app-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { UserConfigComponent } from './user-config/user-config.component';
import { HttpClientModule } from '@angular/common/http';
import { CoverageConfigComponent } from './coverage-config/coverage-config.component';
import { InsuranceTypeSelectModule } from './insurance-type-select/insurance-type-select.module';
import { QuotesConfigComponent } from './quotes-config/quotes-config.component';
import { RateCardComponent } from './rate-card/rate-card.component';
import { PersonalInfoConfigComponent } from './personal-info-config/personal-info-config.component';
import { GoodbyeComponent } from './goodbye/goodbye.component';
import { InsuranceTypesModalComponent } from './insurance-types-modal/insurance-types-modal.component';
import { TypicalCoveragesModalModule } from './typical-coverages-modal/typical-coverages-modal.module';
import { SentryErrorHandlerService } from './listo-common/sentry-error-handler.service';
import { NgxSliderModule } from '@angular-slider/ngx-slider';

@NgModule({
  declarations: [
    AppComponent,
    WelcomeComponent,
    UserConfigComponent,
    CoverageConfigComponent,
    QuotesConfigComponent,
    RateCardComponent,
    PersonalInfoConfigComponent,
    GoodbyeComponent,
    InsuranceTypesModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    ListoCommonModule,
    InsuranceTypeSelectModule,
    TypicalCoveragesModalModule,
    NgxSliderModule
  ],
  providers: [{ provide: ErrorHandler, useClass: SentryErrorHandlerService }],
  bootstrap: [AppComponent],
})
export class AppModule {}
