import { Component, ElementRef, Input } from '@angular/core';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'listo-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent {
  @Input() title: string;
  @Input() description: string;
  element: HTMLElement;
  open = false;

  constructor(private ref: ElementRef) {
    this.element = this.ref.nativeElement;
  }

  onClick() {
    this.open = !this.open;
    if (this.open) {
      const onClick = fromEvent(
        document,
        'click',
        { capture: true },
      ).subscribe(() => {
        onClick.unsubscribe();
        this.open = false;
      });
    }
  }
}
