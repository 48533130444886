import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'listo-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent implements OnChanges {
  @Input() max = 100;
  @Input() min = 0;
  @Input() value = 0;
  @Input() step = 0;
  @Input() possibleValues = [];
  @Output() change = new EventEmitter<number>();

  constructor(private el: ElementRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value || changes.min || changes.max) {
      this.setThumbPosition(
        changes.value ? changes.value.currentValue : this.value,
      );
    }
  }

  setThumbPosition(value: number) {
    let restrictedValue = Math.max(value, this.min);
    restrictedValue = Math.min(restrictedValue, this.max);
    const percentage = (restrictedValue - this.min) / (this.max - this.min) * 100;
    this.el.nativeElement.style.setProperty('--percentage', `${percentage}%`);
  }

  @HostListener('pointerdown', ['$event']) onClick(event) {
    this.onThumbMove(event);

    const onTouchMove = fromEvent(
      document,
      'touchmove',
      { passive: false },
    ).subscribe(event => event.cancelable && event.preventDefault());

    const onPointerMove = fromEvent(
      document,
      'pointermove',
    ).subscribe(this.onThumbMove);

    const onPointerUp = fromEvent(
      document,
      'pointerup',
    ).subscribe(() => {
      onTouchMove.unsubscribe();
      onPointerMove.unsubscribe();
      onPointerUp.unsubscribe();
    });
  };

  onThumbMove = (event: PointerEvent) => {
    event.preventDefault();
    const { left, right, width } = this.el.nativeElement.getBoundingClientRect();

    let xPosition = event.clientX;
    xPosition = Math.max(xPosition, left);
    xPosition = Math.min(xPosition, right);

    const factor = (xPosition - left) / width;

    let value = (this.max - this.min) * factor + this.min;

    value = Math.round(value / this.step) * this.step;

    let isValueValid = true;
    if (this.possibleValues.length > 0) {
      isValueValid = this.possibleValues.includes(value);
    }

    if (isValueValid && value !== this.value) {
      this.change.emit(value);
    }
  }
}
