import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { MONTHS } from '../../../utils/constants';


const getYearsList = () => {
  const currentYear = Number(new Date().getFullYear());
  const eighteenYearsAgo = currentYear - 18;
  return Array.from({ length: 120 }, (_, i) => eighteenYearsAgo - i);
};

const getMonthsList = () => MONTHS.map((month, i) => ({
  value: i,
  label: month,
}));

const getLastDayInMonth = (year, month) => {
  return new Date(year, month + 1, 0).getDate();
};

const getDaysList = (year, month) => {
  const lastDayInMonth = getLastDayInMonth(year, month);
  return Array.from({ length: lastDayInMonth }, (_, i) => i + 1);
};

@Component({
  selector: 'listo-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent {
  @Input() label?: string;
  @Input() required = false;
  @Input() value: Date;
  @Input() form: FormControl;
  @Input() name: string;
  @Output() change: EventEmitter<Date> = new EventEmitter();

  years = getYearsList();

  months = getMonthsList();

  getDays() {
    if (!this.getValue()) {
      return [];
    }
    return getDaysList(this.getYear(), this.getMonth());
  }

  getValue() {
    if (this.form && this.name) {
      return this.form.get(this.name).value;
    }

    return this.value;
  }

  getYear() {
    const value = this.getValue();
    return value && value.getFullYear();
  }

  getMonth() {
    const value = this.getValue();
    return value && value.getMonth();
  }

  getDay() {
    const value = this.getValue();
    return value && value.getDate();
  }

  onYearChange(year) {
    const lastDayInMonth = getLastDayInMonth(year, this.getMonth());
    this.onChange({
      year,
      day: Math.min(this.getDay(), lastDayInMonth) || 1,
    });
  }

  onMonthChange(month) {
    const lastDayInMonth = getLastDayInMonth(this.getYear(), month);
    this.onChange({
      month,
      day: Math.min(this.getDay(), lastDayInMonth) || 1,
    });
  }

  onDayChange(day) {
    this.onChange({ day });
  }

  onChange({ year = this.getYear(), month = this.getMonth(), day = this.getDay() }) {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    date.setFullYear(year, month, day);
    if (year == null) {
      date.setFullYear(year);
    }
    if (month == null) {
      date.setMonth(month);
    }
    if (day == null) {
      date.setDate(day);
    }
    this.change.emit(date);

    if (this.form && this.name) {
      this.form.patchValue({
        [this.name]: date,
      });
    }
  }

  isRequired() {
    if (this.required) {
      return true;
    }
    if (!this.form) {
      return false;
    }
    const control = this.form.get(this.name);
    if (!control || !control.validator) {
      return false;
    }
    const validation = control.validator(<AbstractControl>{});
    return !!(validation && validation.required)
  }

  getError() {
    const control = this.form.get(this.name);
    if (!control || control.untouched || !control.errors) {
      return;
    }
    const type = Object.keys(control.errors)[0];
    return {
      type,
      value: control.errors[type],
    };
  }
}