import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WelcomeComponent } from './welcome/welcome.component';
import { UserConfigComponent } from './user-config/user-config.component';
import { CoverageConfigComponent } from './coverage-config/coverage-config.component';
import { QuotesConfigComponent } from './quotes-config/quotes-config.component';
import { PersonalInfoConfigComponent } from './personal-info-config/personal-info-config.component';
import { GoodbyeComponent } from './goodbye/goodbye.component';

const routes: Routes = [
  {
    path: 'welcome',
    component: WelcomeComponent,
  },
  {
    path: 'user',
    component: UserConfigComponent,
  },
  {
    path: 'coverages',
    component: CoverageConfigComponent,
  },
  {
    path: 'quotes',
    component: QuotesConfigComponent,
  },
  {
    path: 'personal',
    component: PersonalInfoConfigComponent,
  },
  {
    path: 'goodbye',
    component: GoodbyeComponent,
  },
  {
    path: '',
    redirectTo: 'welcome',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
