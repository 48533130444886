import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataStorageService } from '../data-storage.service';
import { Location } from '@angular/common';
import { WebServicesService } from '../web-services.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MAPS_API_KEY } from '../../utils/constants';
import { UserData } from '../../utils/types';

@Component({
  selector: 'app-goodbye',
  templateUrl: './goodbye.component.html',
  styleUrls: ['./goodbye.component.scss'],
})
export class GoodbyeComponent implements OnInit {
  name: string;
  address: string;
  url: SafeResourceUrl;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private storage: DataStorageService,
    private webServices: WebServicesService,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit() {
    const data = this.storage.getData();
    if (data) {
      this.setComponentData(data);
    } else {
      this.router.navigate(['welcome'], { queryParamsHandling: "preserve" } );
    }
  }

  setComponentData(data: UserData) {
    this.name = data.firstName;
    this.webServices.getNearestStore(data.zipCode).subscribe(({
      address,
      phone_number,
      latitude,
      longitude,
    }) => {
      this.address = address;
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
        `https://www.google.com/maps/embed/v1/place?key=${MAPS_API_KEY}&q=${latitude},${longitude}`,
      );
    });
  }

  getAgentLink() {
    const {
      age,
      cid,
      contactPreference,
      contactTime,
      coverage,
      customer_id,
      email,
      firstName,
      gender,
      insuranceType,
      lastName,
      marketingOptIn,
      monthly_premium,
      phoneNumber,
      product,
      provider,
      serviceOptIn,
      sid,
      tobaccoUse,
      transaction_id,
      zipCode,
    } = this.storage.getData();

    const params = new URLSearchParams(<any>{
      ...(age && { age }),
      ...(cid && { cid }),
      ...(contactPreference && { contactpreference: contactPreference }),
      ...(contactTime && { contacttime: contactTime }),
      ...(coverage && { coverage }),
      ...(customer_id && { customerid: customer_id }),
      ...(email && { email }),
      ...(firstName && { firstname: firstName }),
      ...(gender && { gender }),
      ...(insuranceType && { insurancetype: insuranceType }),
      ...(lastName && { lastname1: lastName }),
      ...(marketingOptIn && { marketingoptin: marketingOptIn }),
      ...(monthly_premium && { monthlypayment: monthly_premium }),
      ...(phoneNumber && { phonenumber: phoneNumber }),
      ...(product && { product }),
      ...(provider && { provider }),
      ...(serviceOptIn && { serviceoptin: serviceOptIn }),
      ...(sid && { sid }),
      ...(tobaccoUse && { tobaccouse: tobaccoUse }),
      ...(transaction_id && { transactionid: transaction_id }),
      ...(zipCode && { zipcode: zipCode }),
    });

    return `https://listofin.typeform.com/to/y6QqrE?${params}`;
  }

  onNextClick() {
    this.router.navigate(['welcome'], { queryParamsHandling: "preserve" } );
  }
}
