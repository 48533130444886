import { Component } from '@angular/core';

@Component({
  selector: 'app-insurance-types-modal',
  templateUrl: './insurance-types-modal.component.html',
  styleUrls: ['./insurance-types-modal.component.scss'],
})
export class InsuranceTypesModalComponent {
  id = 'insurances-modal';
}
