import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from '../modal.service';

@Component({
  selector: 'listo-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss'],
})
export class ErrorModalComponent {
  id = 'error-modal';

  constructor(
    private router: Router,
    private modals: ModalService,
  ) {}


  reload() {
    window.location.reload();
  }

  reset() {
    this.modals.close(this.id);
    this.router.navigate(['welcome'], { queryParamsHandling: "preserve" } );
  }
}
