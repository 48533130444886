import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DataStorageService } from '../data-storage.service';
import { TitleCasePipe } from '@angular/common';
import { markFormAsTouched } from '../../utils/operations';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
  providers: [TitleCasePipe],
})
export class WelcomeComponent {
  form = new FormGroup({
    name: new FormControl('', Validators.required),
  });

  constructor(
    private router: Router,
    private storage: DataStorageService,
    private titleCasePipe: TitleCasePipe,
  ) {
    const data = this.storage.getData();
    const referrer = data && data.referrer;
    this.storage.clearData();
    if (referrer) {
      document.location.href = referrer;
    }
  }

  onNextClick() {
    if (this.form.invalid) {
      markFormAsTouched(this.form);
      return;
    }
    const name = this.form.get('name').value;
    this.storage.patchData({
      firstName: this.titleCasePipe.transform(name),
    });
    this.router.navigate(['user'], { queryParamsHandling: "preserve" } );
  }

  onAnonymousClick() {
    this.storage.patchData({});
    this.router.navigate(['user'], { queryParamsHandling: "preserve" } );
  }
}
