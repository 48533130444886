import { Component } from '@angular/core';
import { ModalService } from '../modal.service';
import { Router } from '@angular/router';

@Component({
  selector: 'listo-bottom-bar',
  templateUrl: './bottom-bar.component.html',
  styleUrls: ['./bottom-bar.component.scss'],
})
export class BottomBarComponent {
  constructor(
    private modals: ModalService,
    public router: Router
    ) {}

  openPrivacyModal() {
    this.modals.open('privacy-modal');
  }
}
