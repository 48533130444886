import { Component, OnInit } from '@angular/core';
import { GENDERS, YESNO } from '../../utils/constants';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { getSegmentPage, markFormAsTouched } from 'src/utils/operations';
import { DataStorageService } from '../data-storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { WebServicesService } from '../web-services.service';
import { UserData } from 'src/utils/types';
import CustomValidators from 'src/utils/validators';
import { SegmentService } from '../segment.service';
import { phoneMask } from '../../utils/maks';

@Component({
  selector: 'app-user',
  templateUrl: './user-config.component.html',
  styleUrls: ['./user-config.component.scss'],
})
export class UserConfigComponent implements OnInit {
  name: string;
  form = new FormGroup({
    gender: new FormControl('', Validators.required),
    age: new FormControl(''),
    phoneNumber: new FormControl('', [
      Validators.required,
      Validators.pattern(/^\(\d{3}\) \d{3}-\d{4}$/),
    ]),
    zipCode: new FormControl('', [Validators.required, CustomValidators.number]),
    tobaccoUse: new FormControl('', Validators.required),
    birthdate: new FormControl('', Validators.required),
  });

  phoneMask = phoneMask;
  genders = GENDERS;
  yesno = YESNO;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private storage: DataStorageService,
    private webServices: WebServicesService,
    private segment: SegmentService,
  ) {}

  ngOnInit() {
    const data = this.storage.getData();
    if (data) {
      const birthdate = new Date(new Date(data.birthdate).setMinutes(new Date().getTimezoneOffset()));
      if (birthdate.valueOf()) {
        this.form.patchValue({ birthdate });
      }
      this.setComponentData(data);
    } else {
      this.router.navigate(['welcome'], { queryParamsHandling: "preserve" } );
    }
  }

  setComponentData(data: UserData) {
    this.name = data.firstName;
    this.form.patchValue(data);
    const zipCodeValidator = CustomValidators.webService(
      zipCode => this.webServices.getNearestStore(zipCode),
    );
    this.form.get('zipCode').setAsyncValidators(zipCodeValidator);
    this.form.updateValueAndValidity();
  }

  onNextClick() {
    if (this.form.invalid) {
      markFormAsTouched(this.form);
      return;
    }
    const data = this.form.getRawValue();
    const year = data.birthdate.getFullYear();
    const month = String(data.birthdate.getMonth() + 1).padStart(2, '0');
    const day = data.birthdate.getDate().toString().padStart(2, '0');

    this.storage.patchData({
      ...data,
      birthdate: data.birthdate,
      age: this.calculateAge(`${year}-${month}-${day}`),
    });
    this.segment.track(`${getSegmentPage()} UserEntered`);
    this.router.navigate(['quotes'], { queryParamsHandling: "preserve" } );

    if(this.storage.getData().phoneNumber) {
      this.webServices.catchApplicants(this.storage.getData()).subscribe(() => {});
    }

  }

  onBackClick() {
    this.router.navigate(['welcome'], { queryParamsHandling: "preserve" } );
  }

  calculateAge(dateOfBirth) {
    const dob = new Date(dateOfBirth);
    const currentDate = new Date();

    const difference = currentDate.getTime() - dob.getTime();

    const ageInMilliseconds = new Date(difference);
    const age = Math.abs(ageInMilliseconds.getUTCFullYear() - 1970);

    return age;
  }

}
