import { Component, EventEmitter, Input, Output } from '@angular/core';

interface OptionObject {
  label: string;
  value: any;
}

type Option = OptionObject | string;

@Component({
  selector: 'listo-options',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.scss'],
})
export class OptionsComponent {
  @Input() options: Option[] = [];
  @Input() value: any;
  @Output() change = new EventEmitter<any>();

  getOptionLabel(option: Option): string {
    if (typeof option === 'object') {
      return option.label;
    }
    return option;
  }

  getOptionValue(option: Option): any {
    if (typeof option === 'object') {
      return option.value;
    }
    return option;
  }
}
