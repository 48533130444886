import { Component, Input } from '@angular/core';

@Component({
  selector: 'listo-error-label',
  templateUrl: './error-label.component.html',
  styleUrls: ['./error-label.component.scss'],
})
export class ErrorLabelComponent {
  @Input() error: { type: string, value: any };
}
