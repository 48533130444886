import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InsuranceTypeSelectComponent } from './insurance-type-select.component';
import { InsuranceTypeComponent } from './insurance-type/insurance-type.component';
import { ListoCommonModule } from '../listo-common/listo-common.module';

@NgModule({
  imports: [
    CommonModule,
    ListoCommonModule,
  ],
  declarations: [
    InsuranceTypeSelectComponent,
    InsuranceTypeComponent,
  ],
  exports: [
    InsuranceTypeSelectComponent,
  ],
})
export class InsuranceTypeSelectModule {}
