import { Component, ElementRef, Input, OnInit, OnDestroy } from '@angular/core';
import { ModalService } from '../modal.service';

@Component({
  selector: 'listo-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit, OnDestroy {
  @Input() id: string;
  element: HTMLElement;
  private _open: boolean;

  constructor(
    private ref: ElementRef,
    private modalService: ModalService,
  ) {
    this.element = ref.nativeElement
  }

  ngOnInit() {
    this.modalService.add(this);
  }

  ngOnDestroy() {
    this.modalService.remove(this.id);
  }

  isOpen() {
    return this._open;
  }

  open() {
    this._open = true;
  }

  close() {
    this._open = false;
  }
}
