import { Component } from '@angular/core';

@Component({
  selector: 'app-typical-coverages-modal',
  templateUrl: './typical-coverages-modal.component.html',
  styleUrls: ['./typical-coverages-modal.component.scss'],
})
export class TypicalCoveragesModalComponent {
  id = 'examples-modal';
  case: 0 | 1 | 2 = 0;

  onNextClick() {
    if (this.case === 2) {
      this.case = 0
    } else {
      this.case++;
    }
  }

  onPreviousClick() {
    if (this.case === 0) {
      this.case = 2
    } else {
      this.case--;
    }
  }
}
