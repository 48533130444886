import { Component, OnInit } from '@angular/core';
import { InsuranceType, UserData } from '../../utils/types';
import { WebServicesService } from '../web-services.service';
import { DataStorageService } from '../data-storage.service';
import { map } from 'rxjs/operators';
import { forkJoin, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { LimitsService } from '../limits.service';
import { InsuranceTypesProperties } from '../insurance-type-select/insurance-type-select.component';
import { INSURANCE_TYPE_OPTIONS } from '../../utils/constants';
import { ModalService } from '../listo-common/modal.service';
import { SegmentService } from '../segment.service';
import { getSegmentPage } from '../../utils/operations';

@Component({
  selector: 'app-coverage-config',
  templateUrl: './coverage-config.component.html',
  styleUrls: ['./coverage-config.component.scss'],
})
export class CoverageConfigComponent implements OnInit {
  insuranceTypeAmounts: InsuranceTypesProperties = {};
  insuranceType: InsuranceType = null;
  minCoverage: number;
  maxCoverage: number;
  coverage: number;
  allCoverages: Array<number>;
  loading = false;
  request = new Subscription();

  constructor(
    private webServices: WebServicesService,
    private storage: DataStorageService,
    private modals: ModalService,
    private router: Router,
    private limits: LimitsService,
    private segment: SegmentService,
  ) { }

  onCoverageChange(coverage: number) {
    if (this.coverage !== coverage) {
      this.coverage = coverage;
      this.getAmounts();
    }
  }

  ngOnInit() {
    const data = this.storage.getData();
    if (data) {
      this.setComponentData(data);
    } else {
      this.router.navigate(['welcome'], { queryParamsHandling: "preserve" } );
    }
  }

  setComponentData(data: UserData) {
    this.coverage = data.coverage || 0;
    this.insuranceType = data.insuranceType || null;
    this.setCoverageLimits();
    this.getAmounts();
    this.limits.changes.subscribe(
      () => {
        this.setCoverageLimits();
        this.getAmounts();
      },
    );
  }

  setCoverageLimits() {
    this.minCoverage = this.limits.coverage.min;
    this.maxCoverage = this.limits.coverage.max;
    this.allCoverages = this.limits.allCoverages;
    this.validateCoverage();
  }

  validateCoverage() {
    if (this.coverage < this.minCoverage) {
      this.coverage = this.minCoverage
    } else if (this.coverage > this.maxCoverage) {
      this.coverage = this.maxCoverage
    }
  }

  getAmounts() {
    this.request.unsubscribe();
    this.loading = true;
    const requests = INSURANCE_TYPE_OPTIONS.map(
      ({ value }) => {
        return this.getLowestPayment(value);
      },
    );
    this.request = forkJoin(requests).subscribe(
      (amounts) => {
        const insuranceTypeAmounts = {};
        INSURANCE_TYPE_OPTIONS.forEach(({ value }, i) => {
          insuranceTypeAmounts[value] = amounts[i];
        });
        this.insuranceTypeAmounts = insuranceTypeAmounts;
        this.loading = false;
      },
      () => this.modals.open('error-modal'),
    )
  }

  getLowestPayment(insuranceType: InsuranceType) {
    const { age, gender, tobaccoUse, zipCode } = this.storage.getData();

    return this.webServices.getRates({
      insuranceType,
      age,
      gender,
      tobaccoUse,
      zipCode,
      length: this.getLength(insuranceType),
      coverage: this.coverage,
    }).pipe(map(
      ({ quotes }) => quotes.length ? quotes[0].premium : 0,
    ))
  }

  getLength(insuranceType: InsuranceType) {
    switch (insuranceType) {
      case 'Term Life':
        return this.limits.temporaryLength.min;
      case 'Guaranteed Acceptance Whole Life':
        return this.limits.guaranteedUniversalLength.min;
    }
  }

  openExamplesDialog() {
    this.track('ExamplesLink');
    this.modals.open('examples-modal');
  }

  openInsurancesDialog() {
    this.track('CoverageType');
    this.modals.open('insurances-modal');
  }

  onInsuranceTypeChange(insuranceType: InsuranceType) {
    this.track('Product Clicked', { insuranceType });
    this.insuranceType = insuranceType;
  }

  onNextClick() {
    this.storage.patchData({
      coverage: this.coverage,
      insuranceType: this.insuranceType,
    });
    this.router.navigate(['quotes'] , { queryParamsHandling: "preserve" });
  }

  onBackClick() {
    this.router.navigate(['user'], { queryParamsHandling: "preserve" } );
  }

  track(event, params?) {
    this.segment.track(`${getSegmentPage()} ${event}`, params);
  }
}
