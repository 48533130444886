export const environment = {
  apiUrl: 'https://staging.api2.listofin.com/life-service',
  production: false,
  zipCodeApiUrl: 'https://staging.api2.listofin.com/store-service',
  cookieDomain: 'listofin.com',
  agentServiceUrl: 'https://api.listofin.com/agent-service',
  apiUrlV2: 'https://loans-staging.herokuapp.com',
  tsApiUrl: 'https://gw-demo.developer-nonprod.cunamutual.com/cmfg/demo/',
  tsClientId: 'f8f917cd08fbbcce15a8a5a5219f02ad',
  tsUserName: 'SA-PrtnrApiListo-M',
  tsPassword: '0P2bNCMV5G7Qjhy6tl76',
};
