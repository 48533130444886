import { Component, Input, OnInit } from '@angular/core';
import { ListoCommonService } from '../listo-common.service';

@Component({
  selector: 'listo-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent implements OnInit {
  @Input() steps: string[] = [];
  @Input() currentStep: number;

  constructor(private listoService: ListoCommonService) {}

  ngOnInit() {
    this.steps = this.listoService.steps;
  }
}
