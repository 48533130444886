import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CONTACT_TIMES } from '../../utils/constants';
import { DataStorageService } from '../data-storage.service';
import { WebServicesService } from '../web-services.service';
import { getSegmentPage, markFormAsTouched } from '../../utils/operations';
import { UserData } from '../../utils/types';
import { ModalService } from '../listo-common/modal.service';
import { SegmentService } from '../segment.service';
import { phoneMask } from '../../utils/maks';
import { LEAD_CAME_FROM_OPTIONS } from '../../utils/constants';

@Component({
  selector: 'app-personal-info-config',
  templateUrl: './personal-info-config.component.html',
  styleUrls: ['./personal-info-config.component.scss'],
})
export class PersonalInfoConfigComponent implements OnInit {
  form = new FormGroup({
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    phoneNumber: new FormControl('', Validators.required),
    contactTime: new FormControl(''),
    email: new FormControl(''),
    serviceOptIn: new FormControl(false),
    marketingOptIn: new FormControl(false),
    lead_came_from: new FormControl(''),
  });

  phoneMask = phoneMask;
  companySelected: boolean;
  contactTimes = CONTACT_TIMES;
  lead_came_from_options = LEAD_CAME_FROM_OPTIONS
  loading = false;
  date: Date;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private storage: DataStorageService,
    private webServices: WebServicesService,
    private modals: ModalService,
    private segment: SegmentService,
  ) {}

  ngOnInit() {
    const data = this.storage.getData();
    if (data) {
      this.setComponentData(data);
    } else {
      this.router.navigate(['welcome'], { queryParamsHandling: "preserve" } );
    }
  }

  setComponentData(data: UserData) {
    this.form.patchValue(data);
    this.companySelected = data.monthly_premium !== undefined;
  }

  shouldShowDate() {
    return this.form.get('contactTime').value === 'i_call';
  }

  onDateChange(date) {
    this.date = date;
  }

  onNextClick() {
    if (this.form.invalid) {
      markFormAsTouched(this.form);
      return;
    }
    const formData = this.form.getRawValue();
    this.storage.patchData({
      ...formData,
      contactDate: this.shouldShowDate() && this.date ? this.date.toISOString() : undefined,
    });

    this.loading = true;
    const data = this.storage.getData();
    this.webServices.saveUserInfo(data).subscribe(
      () => {
        this.segment.track(`Product Added`, {
          name: data.product,
          price: data.monthly_premium,
        });
        this.router.navigate(['goodbye'], { queryParamsHandling: "preserve" } );
      },
      () => {
        this.loading = false;
        this.modals.open('error-modal');
      },
    );
    this.webServices.updateApplicant(this.storage.getData()).subscribe(() => {});

  }

  onBackClick() {
    this.router.navigate(['quotes'], { queryParamsHandling: "preserve" } );
  }
}
