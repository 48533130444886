import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

interface Limit {
  min: number;
  max: number;
}

interface Limits {
  age: Limit;
  coverage: Limit;
  allCoverages: Array<number>,
  allGuaranteedUniversalLengths: Array<number>,
  guaranteedUniversalLength: Limit;
  allTemporaryLengths: Array<number>,
  temporaryLength: Limit;
}

const defaultLimit = {
  min: 5000,
  max: 300000,
};

@Injectable({
  providedIn: 'root',
})
export class LimitsService {
  isSet: Boolean;
  private _age: Limit = defaultLimit;
  private _coverage: Limit = defaultLimit;
  private _allCoverages: Array<number> = [];
  private _allGuaranteedUniversalLengths: Array<number> = [];
  private _allTemporaryLengths: Array<number> = [];
  private _guaranteedUniversalLength: Limit = defaultLimit;
  private _temporaryLength: Limit = defaultLimit;
  changes = new Subject();

  get age(): Limit {
    return this._age;
  }

  get coverage(): Limit {
    return this._coverage;
  }

  get allCoverages(): Array<number> {
    return this._allCoverages;
  }

  get allGuaranteedUniversalLengths(): Array<number> {
    return this._allGuaranteedUniversalLengths;
  }

  get guaranteedUniversalLength(): Limit {
    return this._guaranteedUniversalLength;
  }

  get allTemporaryLengths(): Array<number> {
    return this._allTemporaryLengths;
  }

  get temporaryLength(): Limit {
    return this._temporaryLength;
  }

    set({ age, coverage, allCoverages, allGuaranteedUniversalLengths, allTemporaryLengths, guaranteedUniversalLength, temporaryLength }: Limits) {
    this.isSet = true;
    this._age = age;
    this._coverage = coverage;
    this._allCoverages = allCoverages;
    this._allGuaranteedUniversalLengths = allGuaranteedUniversalLengths;
    this._allTemporaryLengths = allTemporaryLengths;
    this._guaranteedUniversalLength = guaranteedUniversalLength;
    this._temporaryLength = temporaryLength;
    this.changes.next();
  }
}
