import { InsuranceTypeOption } from "./types";

export const LANGUAGE = 'en';
export const MAPS_API_KEY = 'AIzaSyCxJR9snzhfGfltMs3zQMg0eONP3ZhB5q8';
export const SEGMENT_API_KEY = '3K5AnU0SRXb2Nhbho69WvVLYn2UuWxQd';
const params = new URLSearchParams(window.location.search);
const isPPC = params.get('sid') === 'ppc';
export const PHONE_NUMBER = isPPC ? '1 (800) 931-8072' : '(408) 610-3084';
export const STEPS = ['About you', 'Coverage', 'Quotes'];
export const GENDERS = [
  {
    value: 'male',
    label: 'Male',
  },
  {
    value: 'female',
    label: 'Female',
  },
];

export const YESNO = [
  {
    value: true,
    label: 'Yes',
  },
  {
    value: false,
    label: 'No',
  },
];

export const CONTACT_TIMES = [
  {
    value: 'morning',
    label: 'During the morning',
  },
  {
    value: 'afternoon',
    label: 'During the afternoon',
  },
  {
    value: 'night',
    label: 'At night',
  },
  {
    value: 'i_call',
    label: 'I prefer to call you',
  },
];

export const INSURANCE_TYPE_OPTIONS: InsuranceTypeOption[] = [
  {
    value: 'Term Life',
    label: 'Term Life',
  },
  {
    value: 'Guaranteed Acceptance Whole Life',
    label: 'Guaranteed Acceptance Whole Life',
  },
  {
    value: 'Whole Life',
    label: 'Whole Life',
  },
];

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const LEAD_CAME_FROM_OPTIONS = [
  "Univision",
  "Relative/Friend",
  "Online Search",
  "Facebook",
  "Radio",
  "Other"
]