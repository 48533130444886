import { Injectable } from '@angular/core';
import { ModalComponent } from './modal/modal.component';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private modals: ModalComponent[] = [];

  private getModal(id: string) {
    return this.modals.find(m => m.id === id)
  }

  add(modal: ModalComponent) {
    document.body.appendChild(modal.element);
    this.modals.push(modal);
  }

  remove(id: string) {
    const modal = this.getModal(id);
    modal.element.remove();
    this.modals = this.modals.filter(m => m.id !== id);
  }

  open(id: string) {
    const modal = this.getModal(id);
    modal.open();
  }

  close(id: string) {
    const modal = this.getModal(id);
    modal.close();
  }
}
