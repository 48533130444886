import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataStorageService } from '../data-storage.service';
import { InsuranceType, UserData, Rate } from '../../utils/types';
import { WebServicesService } from '../web-services.service';
import { Subscription } from 'rxjs';
import { LimitsService } from '../limits.service';
import { INSURANCE_TYPE_OPTIONS } from '../../utils/constants';
import { ModalService } from '../listo-common/modal.service';
import { SegmentService } from '../segment.service';
import { getSegmentPage, mapRateToSegmentProduct } from '../../utils/operations';
import { Options, LabelType } from '@angular-slider/ngx-slider';

@Component({
  selector: 'app-quotes-config',
  templateUrl: './quotes-config.component.html',
  styleUrls: ['./quotes-config.component.scss'],
})
export class QuotesConfigComponent implements OnInit {
  name: string;
  minCoverage: number = 0;
  maxCoverage: number = 0;
  coverage: number = 0;
  coverage2: number = 0;
  allCoverages: Array<number> = [];
  length: number = 0;
  insuranceType: InsuranceType;
  quotes: Rate[] = [];
  loading = false;
  insuranceTypes = INSURANCE_TYPE_OPTIONS;
  request = new Subscription();

  USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });

  slider_settings = {
    'Term Life': { floor: 5000, ceil: 300000, step: 10000 },
    'Guaranteed Acceptance Whole Life': { floor: 2000, ceil: 25000, step: 1000 },
    'Whole Life': { floor: 5000, ceil: 100000, step: 1000 }
  }

  value: number
  options: Options

  constructor(
    private router: Router,
    private storage: DataStorageService,
    private webServices: WebServicesService,
    private limits: LimitsService,
    private modals: ModalService,
    private segment: SegmentService,
  ) {}

  ngOnInit() {
    this.configSlider(this.slider_settings['Term Life'])
    let data = this.storage.getData();
    if (data) {
      if (typeof data.coverage === 'undefined') {
        data.coverage = 0;
      }
      this.setComponentData(data);
    } else {
      this.router.navigate(['welcome'], { queryParamsHandling: "preserve" } );
    }
  }

  setComponentData(data: UserData) {
    this.name = data.firstName;
    this.coverage = data.coverage;
    this.coverage2 = data.coverage2;
    this.insuranceType = !data.insuranceType ? "Term Life" : data.insuranceType;
    this.setLimits();
    // this.getRates(true);
    this.setStateCode();
    this.getQuotes();
    this.limits.changes.subscribe(
      () => {
        this.setLimits();
        this.getQuotes();
        // this.getRates(true);
      },
    );
  }

  setLimits() {
    if (this.limits.isSet) {
      this.minCoverage = 5000//this.limits.coverage.min;
      this.maxCoverage = 300000//this.limits.coverage.max;
      this.validateCoverage();
      this.validateLength();
      this.allCoverages = this.limits.allCoverages;
    }
  }

  getRates(firstTime?: boolean) {
    this.request.unsubscribe();
    const { age, gender, tobaccoUse, zipCode } = this.storage.getData();
    // this.loading = true;
    this.request = this.webServices.getRates({
      insuranceType: this.insuranceType,
      age,
      gender,
      tobaccoUse,
      zipCode,
      length: this.length ? this.length : undefined,
      coverage: this.coverage,
    }).subscribe(
      ({ quotes, customer_id, transaction_id }) => {
        this.storage.patchData({
          customer_id,
          transaction_id,
        });
        this.quotes = quotes;
        if (this.quotes.length) {
          this.trackQuotes(firstTime);
        }
        this.loading = false;
      },
      () => {
        this.quotes = [];
        this.loading = false;
      },
    );
  }

  onCoverageChange2(monthly: number) {
    if (this.coverage2 !== monthly) {
      this.coverage2 = monthly;
      this.getQuotes();
    }
  }

  onUserChangeEnd(event: any) {
    this.coverage = event.value;
    this.coverage2 = event.highValue;
    this.getQuotes();
  }

  onInsuranceTypeChange(insuranceType: InsuranceType) {
    if (this.insuranceType !== insuranceType) {
      this.insuranceType = insuranceType;
      this.configSlider(this.slider_settings[insuranceType]);
      this.getQuotes();
    }
  }

  onLengthChange(length: number) {
    if (this.length !== length) {
      this.length = length;
      this.getQuotes();
    }
  }

  validateCoverage() {
    if (this.coverage < this.minCoverage) {
      this.coverage = this.minCoverage
      this.coverage2 = 25000
    } else if (this.coverage > this.maxCoverage) {
      this.coverage = this.maxCoverage
      this.coverage2 = 25000
    }
  }

  validateLength() {
    if (this.length < this.minLength) {
      this.length = this.minLength
    } else if (this.length > this.maxLength) {
      this.length = this.maxLength
    }
  }

  get minLength() {
    switch (this.insuranceType) {
      case 'Term Life':
        return this.limits.temporaryLength.min;
      case 'Guaranteed Acceptance Whole Life':
        return this.limits.guaranteedUniversalLength.min;
      default:
        return 0;
    }
  }

  get maxLength() {
    switch (this.insuranceType) {
      case 'Term Life':
        return this.limits.temporaryLength.max;
      case 'Guaranteed Acceptance Whole Life':
        return this.limits.guaranteedUniversalLength.max;
      default:
        return 0;
    }
  }

  get possibleLengths() {
    switch (this.insuranceType) {
      case 'Term Life':
        return this.limits.allTemporaryLengths;
      case 'Guaranteed Acceptance Whole Life':
        return this.limits.allGuaranteedUniversalLengths;
      default:
        return 0;
    }
  }

  openExamplesDialog() {
    this.segment.track(`${getSegmentPage()} ExamplesLink`);
    this.modals.open('examples-modal');
  }

  openInsurancesDialog() {
    this.segment.track(`${getSegmentPage()} CoverageType`);
    this.modals.open('insurances-modal');
  }

  onNextClick(quote: Rate) {
    this.storage.patchData({
      monthly_premium: quote && quote.premium,
      product: quote && quote.product,
      provider: quote && quote.companyName,
    });
    if (quote) {
      this.segment.track('Product Clicked', mapRateToSegmentProduct(quote));
    }
    this.router.navigate(['personal'], { queryParamsHandling: "preserve" } );
  }

  onBackClick() {
    this.router.navigate(['user'], { queryParamsHandling: "preserve" } );
  }

  async setStateCode() {
    let location = await this.webServices.getLocation(this.storage.getData().zipCode +'');
  
    this.storage.patchData({
      stateCode: location[0].stateCode
    });
  }

  async getQuotes() {
    try {
      this.loading = true;
      const token = await this.webServices.getAccessToken();
      const response = await this.fetchQuotes(token['access_token']);

      const { quotes } = response;
      const filteredQuotes = this.filterQuotes(quotes);

      const transformedQuotes = this.transformQuotes(filteredQuotes);
      this.quotes = transformedQuotes.slice(0, 5);

      this.loading = false;
    } catch (error) {
      this.handleError();
    }
  }
  
  private async fetchQuotes(token: string) {
    const data = this.storage.getData();
    const step = this.insuranceType === 'Term Life' ? 5000 : 1000;
    const { coverage, coverage2 } = this;
  
    return await this.webServices.getQuotes(token, data, coverage, coverage2, step ).toPromise();
  }
  
  private filterQuotes(quotes: any[]) {
    let filteredQuotes = this.exclude2020Products(quotes);

    if (this.insuranceType === 'Guaranteed Acceptance Whole Life') {
      filteredQuotes = this.filterForGuaranteedAcceptance(filteredQuotes);
    } else if (this.insuranceType === 'Term Life') {
      filteredQuotes = this.filterForTermLife(filteredQuotes);
    } else if (this.insuranceType === 'Whole Life') {
      filteredQuotes = this.filterForWholeLife(quotes);
    }
  
    return filteredQuotes;
  }
  
  private exclude2020Products(quotes: any[]) {
    return quotes.filter((quote: any) => !quote.productCode.includes('2020'));
  }
  
  private filterForGuaranteedAcceptance(quotes: any[]) {
    return quotes.filter((quote: any) => quote.productCode === '2016 GA Whole Life');
  }
  
  private filterForTermLife(quotes: any[]) {
    return quotes
      .filter((quote: any) => quote.coverageOptions)
      .map((quote: any) => {
        const coverageOptions = quote.coverageOptions.filter((option: any) =>
          option.productCode === '2016 SITERM80' || option.productCode === '2017 SITERM80 B2'
        );
  
        return {
          ...quote,
          coverageOptions
        };
      });
  }

  private filterForWholeLife(quotes: any[]) {
    return quotes.filter((quote: any) => quote.productCode.includes('2020'));
  }
  
  private transformQuotes(quotes: any[]) {
    const newQuotes = [];
  
    quotes.forEach((quote) => {
      quote.coverageOptions.forEach((coverage) => {
        const newQuote: any = {};
  
        if (this.insuranceType === 'Term Life') {
          newQuote['productCode'] = coverage.productCode;
        } else {
          newQuote['productCode'] = quote.productCode;
        }
  
        newQuote['applying'] = false;
        newQuote['product'] = quote.productCode;
        newQuote['companyName'] = 'TruStage';
        newQuote['coverage'] = coverage.coverage;
  
        coverage.premiums.forEach((premium) => {
          newQuote['frequency'] = premium.frequency;
          newQuote['premium'] = premium.premium;
        });
  
        newQuote['logo'] = 'https://trustage.s3.amazonaws.com/TruStage_Standard_Logo_RGB.png';
        newQuotes.push(newQuote);
      });
    });
  
    return newQuotes;
  }
  
  private handleError() {
    this.quotes = [];
    this.loading = false;
  }
  
  async apply(quote: Rate) {
    quote.applying = true;
    const token = await this.webServices.getAccessToken();

    this.webServices.apply(
      token['access_token'],
      this.storage.getData(),
      quote,
    ).subscribe(
      ({ application }) => {
        this.storage.patchData({
          applicationId: application['applicationId'],
          tsUrl: application['url']
        });
        window.open(application['url'], "_blank");
        quote.applying = false;
        this.webServices.markApplicantAsCompleted(this.storage.getData()).subscribe(() => {});
      },
      () => {
        quote.applying = false;
      },
    );
  }

  trackQuotes(firstTime?: boolean) {
    this.segment.track(`Product List ${firstTime ? 'Viewed' : 'Filtered'}`, {
      filters: [
        {
          type: 'coverage',
          value: this.coverage,
        },
        {
          type: 'insuranceType',
          value: this.insuranceType,
        },
        {
          type: 'length',
          value: this.length,
        },
      ],
      products: this.quotes.map(mapRateToSegmentProduct),
    });
  }

  configSlider(slider_settings) {
    this.coverage = slider_settings.floor;
    this.value = slider_settings.floor;
    this.options = {
      floor: slider_settings.floor,
      ceil: slider_settings.ceil,
      step: slider_settings.step,
      translate: (value: number): string => {
        if (value == slider_settings.floor){
          return '<b>Min:</b>' + this.USDollar.format(value);
        } else if (value == slider_settings.ceil){
          return '<b>Max:</b>' + this.USDollar.format(value);
        } else {
          return this.USDollar.format(value);
        }
      }
    }
  }
}

