import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TypicalCoveragesModalComponent } from './typical-coverages-modal.component';
import { FigureComponent } from './figure/figure.component';
import { ListoCommonModule } from '../listo-common/listo-common.module';

@NgModule({
  imports: [
    CommonModule,
    ListoCommonModule,
  ],
  declarations: [
    TypicalCoveragesModalComponent,
    FigureComponent,
  ],
  exports: [
    TypicalCoveragesModalComponent,
  ],
})
export class TypicalCoveragesModalModule {}
