import { Component } from '@angular/core';
import { LANGUAGE } from '../../../utils/constants';
import { ModalService } from '../modal.service';

@Component({
  selector: 'listo-privacy-modal',
  templateUrl: './privacy-modal.component.html',
  styleUrls: ['./privacy-modal.component.scss'],
})
export class PrivacyModalComponent {
  id = 'privacy-modal';
  lang = LANGUAGE;

  constructor(private modals: ModalService) {}

  close() {
    this.modals.close(this.id);
  }
}
