import { SegmentService } from './segment.service';
import { Component, OnInit } from '@angular/core';
import { QueryParamAnalyticsService } from './query-param-analytics.service';
import { ListoCommonService } from './listo-common/listo-common.service';
import { PHONE_NUMBER, STEPS } from "../utils/constants";
import { LimitsService } from './limits.service';
import { WebServicesService } from './web-services.service';
import { getMinMax } from '../utils/operations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private queryParamAnalytics: QueryParamAnalyticsService,
    private segment: SegmentService,
    private listoCommon: ListoCommonService,
    private limits: LimitsService,
    private webServices: WebServicesService,
  ) {}

  ngOnInit() {
    this.listoCommon.phone = PHONE_NUMBER;
    this.listoCommon.steps = STEPS;

    this.webServices.getLimits().subscribe(limits => {
      this.limits.set({
        age: getMinMax(limits.age),
        coverage: getMinMax(limits.coverage),
        allCoverages: limits.coverage,
        allGuaranteedUniversalLengths: limits.guaranteedUniversalLength,
        guaranteedUniversalLength: getMinMax(limits.guaranteedUniversalLength),
        allTemporaryLengths: limits.temporaryLength,
        temporaryLength: {
          min: 10,
          max: Math.max(...limits.temporaryLength),
        },
      })
    })
  }
}
