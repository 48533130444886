import { Rate } from './types';

export const markFormAsTouched = (form: { controls?: any, markAsTouched(): void }) => {
  form.markAsTouched();
  if (form.controls) {
    Object.values(form.controls).forEach(markFormAsTouched);
  }
};

export const sortRates = (a: Rate, b: Rate) => a.premium - b.premium;

export const debounceFunction = (callback: (...params: any[]) => any, time: number) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(
      () => callback(...args),
      time,
    );
  };
};

export const getMinMax = (values: number[]) => ({
  min: Math.min(...values),
  max: Math.max(...values),
});

export const getSegmentPage = () => {
  const page = window.location.pathname;
  switch (page) {
    case '/welcome':
      return 'LifeInsuranceRater WelcomePage';
    case '/user':
      return 'LifeInsuranceRater UserPage';
    case '/coverages':
      return 'LifeInsuranceRater CoveragesPage';
    case '/quotes':
      return 'LifeInsuranceRater QuotesPage';
    case '/personal':
      return 'LifeInsuranceRater PersonalPage';
    case '/goodbye':
      return 'LifeInsuranceRater GoodbyePage';
    default:
      return page;
  }
};


export const mapRateToSegmentProduct = (rate: Rate, i?: number) => {
  const isInArray = i !== undefined;
  return {
    price: rate.premium,
    ...(isInArray
        ? {
          name: rate.companyName,
          position: i + 1,
        }
        : {
          product_name: rate.companyName,
        }
    ),
  };
};
