import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';

@Component({
  selector: 'listo-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit {
  authenticated: boolean;

  constructor(private auth: AuthService) {}

  ngOnInit() {
    this.auth.authChanges.subscribe(authenticated => {
      this.authenticated = authenticated;
    });
  }
}
