import { Directive, HostListener, Input } from '@angular/core';
import { SegmentService } from './segment.service';

@Directive({
  selector: '[appTrackOnClick]',
})
export class TrackOnClickDirective {
  @Input() eventName: string;
  @Input() eventExtras?: any;

  constructor(
    private segmentService: SegmentService,
  ) {}

  @HostListener('click') onMouseEnter() {
    this.segmentService.track(this.eventName, this.eventExtras);
  }
}
