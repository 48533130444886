import { AbstractControl, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export default {
  webService(webService: (any) => Observable<any>) {
    return ({ value }: AbstractControl): Observable<ValidationErrors | null> => {
      return webService(value).pipe(
        map(() => null),
        catchError((): Observable<ValidationErrors> => of({
            invalid: value,
          }),
        ),
      );
    };
  },

  number({ value }: AbstractControl): ValidationErrors | null {
    return Number.isInteger(Number(value)) ? null : {
      number: value,
    };
  },
};
