import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { DataStorageService } from './data-storage.service';
import { UserData, SegmentAnalytics } from '../utils/types';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { SEGMENT_API_KEY } from '../utils/constants';
import { getSegmentPage } from '../utils/operations';
import { AuthService } from './listo-common/auth.service';

declare const analytics: SegmentAnalytics;

@Injectable({
  providedIn: 'root',
})
export class SegmentService {
  constructor(
    private router: Router,
    private storage: DataStorageService,
    private auth: AuthService,
  ) {
    if (typeof analytics === 'undefined') {
      // @ts-ignore
      window.analytics = new SegmentAnalytics();
    }
    this.router.events.subscribe(this.onRouteChange);
    this.storage.changes.subscribe(SegmentService.onStorageChange);
    if (environment.production) {
      SegmentService.loadScript();
    }
  }

  static loadScript() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = `https://cdn.segment.com/analytics.js/v1/${SEGMENT_API_KEY}/analytics.min.js`;

    const first = document.getElementsByTagName('script')[0];
    first.parentNode.insertBefore(script, first);
  }

  onRouteChange = (event: RouterEvent) => {
    if (event instanceof NavigationEnd) {
      this.page(`${getSegmentPage().replace('Page', '')} PageLoad`);
    }
  };

  static onStorageChange(userData: UserData) {
    if (!userData) {
      return;
    }

    const { firstName, lastName, phoneNumber, email } = userData;

    if (!firstName && !lastName && !phoneNumber && !email) {
      return;
    }

    const data: any = {};

    if (firstName) {
      data.firstName = firstName;
    }
    if (lastName) {
      data.lastName = lastName;
    }
    if (email) {
      data.email = email;
    }
    if (phoneNumber) {
      data.phoneNumber = phoneNumber;
    }
    analytics.identify(data);
  }

  static getId() {
    return typeof analytics !== 'undefined' && analytics.user().anonymousId();
  }

  page(eventName?: string, properties?: any) {
    const isAgent = this.auth.isAuthenticated;
    analytics.page(eventName, {
      ...properties,
      ...this.storage.getData(),
      isLoggedInUser: isAgent,
      isAgent,
    });
  }

  track(eventName: string, properties?: any) {
    const isAgent = this.auth.isAuthenticated;
    analytics.track(eventName, {
      ...properties,
      ...this.storage.getData(),
      isLoggedInUser: isAgent,
      isAgent,
    });
  }
}
